.ag-theme-alpine {
    /* --ag-header-height: 30px; */
    --ag-header-foreground-color: #105684 !important;
    --ag-header-background-color: white;
    /* --ag-header-cell-hover-background-color: rgb(187, 151, 242); */
    /* --ag-header-cell-moving-background-color: rgb(80, 40, 140); */
    --ag-background-color: white;
    /* --ag-odd-row-background-color: #daedc8; */
}

.ag-root-wrapper.ag-layout-normal {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 9px 0px, rgba(59, 59, 59, 0.141) 0px -1px 9px 0px !important; */
    /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;   */
}

.ag-input-field-input {
    color: black !important;
}

.ag-icon-filter::before {
    display: none;
}

.ag-floating-filter-button-button {
    display: none;
}

.ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
    border: none !important;
    border-color: none;
    border-style: none;
    outline: 0;
}
.ag-header-cell-text {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px !important;
}

/* .ag-cell-value,
.ag-group-value {
    line-height: normal;
} */

.ag-cell {
    font-size: 15px !important;
}

.pgn_tbl_footer{
    background-color: white;
    border-bottom: solid 2px #46404d63;
    border-left: solid 2px #46404d63;
    border-right: solid 2px #46404d63;
    padding: 5px;
}

.table_footer_pagination p{
    margin-bottom: 0px !important;
}
.pg_btn{
    border: none !important;
}
.ag-paging-page-size{
    display: none !important;
}

.item_table thead, tbody, tfoot, tr, td, th {
  
    border: solid 1px grey !important;
}
.item_table thead th {
    position: sticky;
    top: 0;
    background-color: #ffffff;
    z-index: 999;
  }
  /* .head_table tr th{
    background-color: #105684 !important;
    color: white !important;
  } */