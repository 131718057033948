.items_filer h5 {
  color: rgb(92, 92, 92);
  margin-bottom: 0px;
}

.main-container {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 9px 0px, rgba(0, 0, 0, 0.20) 0px -1px 9px 0px;
  border-radius: 10px !important;
  margin-top: 10px;
  padding: 15px !important;
  height: 60vh;

}

.item-container {
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 15px;
  border: solid 2px rgb(151, 150, 150) !important;
  border-radius: 10px !important;
  width: 600px;

}

.item-container:hover {
  background-color: rgba(184, 179, 179, 0.705);
  padding: 15px;

}

.items_data p {
  margin-bottom: 0px;
  color: grey;
}

.items_data span {
  margin-bottom: 0px;
  color: black;
}

.item_details p {
  margin-bottom: 0px;
  font-size: 16px;
  margin-top: 5px;
  font-weight: 500;
  color: rgb(87, 86, 86);
}

.item_details span {
  margin-bottom: 0px;
  color: black;
}

.order_details p {
  color: rgb(133, 131, 131);
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;

}

.order_details span {
  color: rgb(26, 25, 25);
  font-size: 18px;
  text-transform: capitalize;
}
.boot_table{
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 9px 0px, rgba(0, 0, 0, 0.20) 0px -1px 2px 0px !important;
  border-radius: 10px;
}
.table_header tr th {
  color: #105684 !important;
}
.table_header{
  border-radius: 10px !important;

}