.layout_head h5 {
    color: #105684;
}

.empty_section {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    justify-content: center;
}

.empty_section span {
    color: #9d9d9d;
    font-size: 18px;

    font-weight: 600;
}

.form_layout {
    padding: 20px;
    border-radius: 10px;
    margin-top: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 9px 0px, rgba(0, 0, 0, 0.20) 0px -1px 9px 0px;
}