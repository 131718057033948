.form_label {
    font-size: 14px;
    color: #7a7a7a;
    font-weight: 500;
}

.form_input {
    border: 2px solid grey !important;
}

.form_input:focus {
    border-color: #105684 !important;
    box-shadow: none !important;
}

.react_select .select__control {
    border: 2px solid #dee2e6 !important;
    border-radius: 0.375rem !important;
    font-weight: 600;
}

.react_select .css-t3ipsp-control:focus {
    border-color: #105684 !important;
}

.react_select .css-t3ipsp-control {
    box-shadow: 0 0 0 2px #105684 !important;
}
.date_picker{
    width: 100% !important;
    padding: 6px !important;
    border-radius: 5px !important;
}
.react-datepicker-ignore-onclickoutside{
    border-color: #105684 !important;
}

.psw_icon {
    position: absolute;
    top: 40px;
    right: 20px;
  }
  .form-login-input {
    border: 2px solid #999a9b !important;
    padding: 8px !important;
    font-weight: 400;
    padding: 10px 10px 10px 40px !important;
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
  
  }
  /* .form-login-input:focus {
    border: 2px solid rgb(34, 34, 34) !important;
    box-shadow: none !important;
  } */
  .input_icon {
    position: absolute;
    top: 11px;
    z-index: 111999;
    border-radius: 10px;
    left: 10px;
  }

  .forgot_psw_txt p {
    margin-bottom: 0px;
    text-align: left;
    font-weight: 14px;
    font-weight: 600;
    color: #1a1a27 !important;
  }