.dashboard_head h3 {
    color: #121136;
    font-weight: 500 !important;
}

.dashboard_card {
    padding: 35px 25px;
    box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 1px 11px 0px rgba(0, 0, 0, 0.07) !important;
    transition: transform .3s;

}

.dashboard_card:hover {
    cursor: pointer;
    transform: scale(1.06);
    /* padding: 35px 25px; */
    transition: all ease 0.5s;
}

.dashboard_card h4 {
    font-weight: 700 !important;
    font-size: 18px;
    color: #105684;
    margin-bottom: 0px;
}

.dashboard_card h3 {
    color: #303137 !important;
    margin-top: 20px;
    font-size: 22px;
    margin-bottom: 0px;
}

.Subscription_card {
    padding: 35px 25px;
    box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 1px 11px 0px rgba(0, 0, 0, 0.07) !important;

}

.Subscription_card h4 {
    font-weight: 700 !important;
    font-size: 20px;
    color: #3b3f5c;
    margin-bottom: 0px;
}

.Subscription_card h3 {
    color: #515365 !important;
    margin-top: 20px;
    font-weight: 700 !important;
    font-size: 22px;
    margin-bottom: 0px;
}

.Subscription_card p {
    font-weight: 700 !important;
    font-size: 22px;
    color: #3b3f5c;
    margin-bottom: 0px;
}

.Subscription_card span {
    font-weight: 700 !important;
    font-size: 22px;
    color: #515365 !important;
    margin-bottom: 0px;
}