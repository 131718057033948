.login_bg {
   /* background-image: linear-gradient(to top, #0b476e, #105684, #306e97, #4a87af, #77accf); */
   background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(82, 43, 44, 0.3)),url(../../images/login\ bg.jpg);
    text-align: center;
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.logo-img {
    height: 100% !important;
}

.login-container {
    height: 100vh;
}

.login-details {
    padding: 80px 50px 80px 50px;
    /* max-width: 550px; */
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 4px 25px rgba(0, 18, 48, 0.25);
}

.login_form_head {
    text-align: center;
}

.login_form_head h5 {
    margin-bottom: 0px;
    /* color: #105684; */
    color: grey;
    font-weight: 600;
}

.login_form_head p {
    margin-bottom: 0px;
    font-size: 16px;
    color: #888ea8;
    font-weight: 500;
    margin-top: 5px;
}

.forgot_psw_txt p {
    margin-bottom: 0px;
    text-align: left;
    font-weight: 14px;
    font-weight: 600;
    color: #1a1a27 !important;
}

.form-login-input {
    border: 2px solid rgb(153, 153, 153) !important;
    color: #1a1a27;
    padding: 10px 10px 10px 40px !important;
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
    font-weight: 500;
}

.input_icon {
    position: absolute;
    top: 11px;
    z-index: 111999;
    border-radius: 10px;
    left: 10px;
}

.login_label {
    font-size: 16px !important;
    color: #888ea8 !important;
    font-weight: 500 !important;
}

.form-login-input:focus {
    border: 2px solid #105684 !important;
    box-shadow: none !important;
}

.login_btn {
    width: 180px;
    font-weight: 500 !important;
    background-color: #105684 !important;
    color: white !important;
    border-radius: 5px !important;
    border: solid 2px #105684 !important;
    font-weight: 600;
}

.login_btn:hover {
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.04), rgba(255, 255, 255, 0.20)) !important;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .06) !important;
    transition: all ease 0.5s !important;
}