.material_detals p {
    margin-bottom: 0px !important;
    color: grey;
    font-weight: 500;
}
.material_detals span {
    margin-bottom: 0px !important;
    color: rgb(37, 37, 37);
    font-weight: 500;
}

.pdf_content_sec h5{
    text-align: center;
}
.pdf_content_sec p{
    margin-bottom: 0px;
    color: black;
    font-size: 12px;
    font-weight: 500;
}
.pdf_content_sec span{
    font-size: 12px;
    margin-bottom: 0px !important;
}