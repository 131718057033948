/* #dashboard-sidebar {
    position: fixed;
    width: 235px !important;
    padding: 0px !important;
    color: white;
    z-index: 999;
    background-color: #173e61 !important;
    animation-name: dev;
    animation-duration: 0.9s;
  }
  
  @keyframes dev {
    from {
      transform: translateX(-220px);
    }
  
    to {
      transform: translateX(0px);
    }
  }
  
  .profile-header {
    padding: 25px 25px 25px 25px !important;
    border-bottom: solid 1px rgba(242, 244, 248, 0.3) !important;
  }
  
  .profile-sidebar-body {
    padding: 0px 0px 30px 0px !important;
    height: 78vh;
    background-color: #173e61  !important;

  }
  
  .profile-sidebar-body h2 {
    margin-top: 7px;
  }
  
  .sidebar-logo-section h5 {
    margin-top: 10px;
    margin-bottom: 0px;
  }
  
  .sidebar-logo-section p {
    margin-top: -10px;
    margin-bottom: 0px;
  }
  
  .user-img {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 50% !important;
    height: 50px !important;
    width: 50px !important;
  }
  
  .new-accordion {
    background-color: #173e61  !important;
    border: none !important;
  }
  
  .acc-btn {
    background-color: #173e61  !important; 
    color: white !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    border-right: none;
    border-bottom: solid 1px rgba(242, 244, 248, 0.3) !important;
    border-top: none !important;
    border-left: none !important;
    text-align: left !important;
    align-items: center !important;
    width: 100%;
    padding: 10px 10px 10px 35px !important;
  }
  
  .acc-btn:hover {
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.04), rgba(255, 255, 255, 0.20)) !important;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .06) !important;
    transition: all ease 0.5s !important;
  
  }
  
  .acc-btn:focus {
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.04), rgba(255, 255, 255, 0.20)) !important;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .06) !important;
    transition: all ease 0.5s !important;
  }
  
  .accordion-body {
    padding: 0px !important;
    background-color: #173e61  !important; 
  
  }
  
  .acc-list {
    margin-bottom: 10px;
    margin-left: -30px;
  }
  
  .acc-list ul {
    padding-left: 0px;
    margin-top: 5px !important;
    margin-bottom: -5px !important;
  }
  
  .acc-list li {
    font-size: 14px !important;
    font-weight: 500;
    list-style-type: none;
    text-align: left;
    padding-top: 5px;
    padding-left: 42px;
    color: white;
  }
  
  .acc-list li:hover {
    background-color: #162d3c50 !important;
  }
  
  .acc-list li:focus {
    background-color: #162d3c50 !important;
  }
   */
   

 #dashboard-sidebar {
  position: fixed;
  width: 235px !important;
  padding: 0px !important;
  color: white;
  z-index: 999;
  background-color:  #173e61 !important;
  animation-name: dev;
  border-top-right-radius: 50px;
  animation-duration: 0.9s;
}

@keyframes dev {
  from {
    transform: translateX(-220px);
  }

  to {
    transform: translateX(0px);
  }
}

.profile-header {
  padding: 25px 25px 25px 25px !important;
  border-bottom: solid 1px rgba(242, 244, 248, 0.3) !important;
}

.profile-sidebar-body {
  padding: 0px 0px 30px 0px !important;
  height: 78vh;
  background-color:  #173e61  !important;

}



.sidebar-logo-section h5 {
  margin-top: 10px;
  margin-bottom: 0px;
}

.sidebar-logo-section p {
  margin-top: -10px;
  margin-bottom: 0px;
}

.user-img {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 50% !important;
  height: 50px !important;
  width: 50px !important;
}

.new-accordion {
  background-color:  #173e61  !important;
  border: none !important;
}

.acc_btn .accordion-button {
  background-color:  #173e61  !important; 
  color: white !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  border-right: none;
  border-bottom: solid 1px rgba(242, 244, 248, 0.3) !important;
  border-top: none !important;
  border-left: none !important;
  text-align: left !important;
  align-items: center !important;
  width: 100%;
  padding: 10px 10px 10px 20px !important;
}
.accordion-item {
  background-color: #173e61 !important;
  border: none !important;
}
.accordion-button::after {
  display: none !important;
  
}


.accordion-button:hover {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.04), rgba(255, 255, 255, 0.20)) !important;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .06) !important;
  transition: all ease 0.5s !important;

}

.accordion-button:focus {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.04), rgba(255, 255, 255, 0.20)) !important;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .06) !important;
  transition: all ease 0.5s !important;
  
  
}

.accordion-body {
  padding: 0px !important;
  background-color:  #173e61  !important; 

}

.acc-list {
  margin-bottom: 10px;
  margin-left: -30px;
}

.acc-list ul {
  padding-left: 0px;
  margin-top: 5px !important;
  margin-bottom: -5px !important;
}

.acc-list li {
  font-size: 14px !important;
  font-weight: 500;
  list-style-type: none;
  text-align: left;
  padding-top: 5px;
  padding-left: 30px;
  color: white;
}

.acc-list li:hover {
  background-color: #385a70 !important;
}
.active li{
  /* background-color: #6c7074 !important; */
  color: rgb(240, 47, 47) !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.acc-list li:focus {
  background-color: #162d3c50 !important;
}
.accordion-button:not(.collapsed){ 
  box-shadow:none
}

