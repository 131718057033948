/* BUTTON CSS START  */

.save_btn {
    background-color: #105684 !important;
    /* width: 145px !important; */
    color: white !important;
    font-size: 15px !important;
    font-weight: 500;
    padding: 6px 10px ;
    border-radius: 5px !important;
    border: solid 2px #105684 !important;
}

.save_btn:hover {
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.04), rgba(255, 255, 255, 0.20)) !important;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .06) !important;
    transition: all ease 0.1s !important;
}

.add_btn {
    width: 130px !important;
    background-color: #105684 !important;
    color: white !important;
    font-weight: 600;
    border-radius: 5px !important;
    border: solid 2px #105684 !important;
}

.add_btn_btn:hover {
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.04), rgba(255, 255, 255, 0.20)) !important;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .06) !important;
    transition: all ease 0.5s !important;
}

.approve_btn{
    width: 100px !important;
    background-color: #23bb0e !important;
    color: white !important;
    font-weight: 600;
    font-size: 14px !important;
    padding: 5px;
    margin-bottom: 5px;
    border-radius: 5px !important;
    border: solid 2px #23bb0e !important;
}
.update_btn{
    width: 80px !important;
    background-color: #23bb0e !important;
    color: white !important;
    font-weight: 600;
    font-size: 14px !important;
    padding: 5px;
    margin-bottom: 5px;
    border-radius: 5px !important;
    border: solid 2px #23bb0e !important;
}

/* BUTTON CSS END  */